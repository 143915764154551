export default class pageNotFound extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("pageNotFound-page-template");
    const content = template.content.cloneNode(true);
    this.root.appendChild(content);
  }

  connectedCallback() {
    this.render();
  }
  render() {
    const divElement = document.createElement("div");
    // const h1Element = document.createElement("h1");
    // h1Element.innerText = "Hello world";
    // divElement.appendChild(h1Element);
    this.root.appendChild(divElement);
  }
}

customElements.define("failed-page", pageNotFound);
