const Router = {
  clickable: true,

  init: (options = {}) => {
    document.querySelectorAll("a.navlink").forEach((a) => {
      a.addEventListener("click", async (event) => {
        event.preventDefault();

        if (Router.clickable) {
          Router.clickable = false;
          const href = event.target.getAttribute("href");
          await Router.go(href, options);
          Router.clickable = true;
        }
      });
    });
    window.addEventListener("popstate", async (event) => {
      if (Router.clickable) {
        Router.clickable = false;
        await Router.go(event.state ? event.state.route : "/", {
          ...options,
          addToHistory: false,
        });
        Router.clickable = true;
      }
    });
    Router.go(location.pathname, options);
  },

  go: async (route, options = {}) => {
    const { addToHistory = true, routes = {} } = options;
    if (routes[route]) {
      if (addToHistory) {
        history.pushState({ route }, "", route);
      }
    } else {
      if (addToHistory) {
        history.pushState({ route }, "", route.route);
      }
    }

    let pageElement = null;

    if (routes[route]) {
      const PageComponent = routes[route];
      pageElement = document.createElement(PageComponent);
    } else {
      const PageComponent = route.component;
      pageElement = document.createElement(PageComponent);
    }

    if (pageElement) {
      let currentPage = document.querySelector("main").firstElementChild;

      if (currentPage) {
        await Router.fadeOutAndRemove(currentPage);
      }

      document.querySelector("main").appendChild(pageElement);
      await Router.fadeIn(pageElement);
    }

    window.scrollX = 0;
  },

  fadeIn: async (element) => {
    await element.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 200,
    }).finished;
  },

  fadeOutAndRemove: async (element) => {
    await element.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 200,
    }).finished;

    element.remove();
  },
};

export default Router;
