import {
  addDoc,
  auth,
  collection,
  createUserWithEmailAndPassword,
  db,
  getDocs,
  provider,
  query,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  where,
} from "../../firebase/firebaseConfig.js";
import {
  SignOutUser,
  checkislogedIn,
  delteUserFromDB,
  resetPassword,
} from "../services/auth.js";
import { throwSnackbar } from "../services/snackBar.js";
import css from "./loginCSS.js";
export default class login extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("login-page-template");
    const styles = document.createElement("style");
    const content = template.content.cloneNode(true);
    this.root.appendChild(content);
    this.root.appendChild(styles);
    async function loadCSS() {
      styles.textContent = css;
    }
    loadCSS();
  }
  async connectedCallback() {
    redirect();
    async function redirect() {
      const islogedIn = await checkislogedIn();
      if (islogedIn == true) {
        const routes = {
          route: "/qr-codes",
          component: "qr-code-list-page",
        };
        app.router.go(routes);
        return;
      }
    }
    this.render();
  }
  render() {
    const loginButton = this.root.getElementById("googleLogin");
    loginButton.onclick = (event) => {
      event.preventDefault();
      this.signInWithGoogle();
    };
    const loginWithEmail = this.root.getElementById("loginWithEmail");
    loginWithEmail.onclick = (event) => {
      event.preventDefault();
      const emailValue = this.root.getElementById("emailSignIn");
      const email = emailValue.value;
      const passwordValue = this.root.getElementById("passwordLogin");
      const password = passwordValue.value;
      this.signInWithEmail(email, password);
    };
  }
  signInWithGoogle() {
    signInWithPopup(auth, provider).then((result) => {
      if (
        result.user?.email?.endsWith("@pomino.ch") ||
        result.user?.email?.endsWith("@ohooo.ch")
      ) {
        this.saveUserData(result.user);
        this.checkIfUserExist(result.user.uid).then((userExists) => {
          if (!userExists) {
            this.createNewUserDocument(result.user);
          }
        });
      } else {
        SignOutUser();
        delteUserFromDB();
        throwSnackbar("Keine Berechtigung zum anmelden", "black", "red");
      }
    });
  }

  signInWithEmail(email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        this.saveUserData(userCredential.user);
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        throwSnackbar("Ihre Anmeldedtaen sind nicht korrekt", "black", "red");
      });
  }

  async createUserAccount(email) {
    try {
      const password = this.generatePass();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      resetPassword(email);

      const userExists = await this.checkIfUserExist(user.uid);

      if (!userExists) {
        await this.createNewUserDocument(user);
      }

      // Festlegen des photoURL-Attributs für den Benutzer
      const photoURL =
        "https://firebasestorage.googleapis.com/v0/b/qr-ohooo.appspot.com/o/profileImages%2Fuser-icon-default.png?alt=media&token=1b54e5c0-cece-4289-97b8-b27803603245";
      await updateProfile(user, { photoURL: photoURL });

      return user.uid;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Fehler:", error);
      throw error;
    }
  }

  generatePass() {
    let pass = "";
    let str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 30; i++) {
      let char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }
    return pass;
  }

  async saveUserData(userData) {
    await this.saveLanguage(userData.uid);
    localStorage.setItem("user", JSON.stringify(userData));
    const routes = {
      route: "/qr-codes",
      component: "qr-code-list-page",
    };
    app.router.go(routes);
  }

  async saveLanguage(userId) {
    const usersCollectionRef = collection(db, "users");
    try {
      const querySnapshot = await getDocs(
        query(usersCollectionRef, where("uid", "==", userId))
      );

      if (querySnapshot.size > 0) {
        const userData = querySnapshot.docs[0].data();
        localStorage.setItem("language", JSON.stringify(userData.language));
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  async checkIfUserExist(userId) {
    const userCollection = collection(db, "users");
    let querySnapshot;
    let userData = "";

    try {
      querySnapshot = await getDocs(
        query(userCollection, where("uid", "==", userId))
      );
      console.log("userID", userId);
      querySnapshot.forEach((doc) => {
        userData = doc.data();
      });
      if (userData) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async checkIfUserExistByMail(email) {
    const userCollection = collection(db, "users");
    let querySnapshot;
    let userData = "";

    try {
      querySnapshot = await getDocs(
        query(userCollection, where("email", "==", email))
      );
      querySnapshot.forEach((doc) => {
        userData = doc.data();
      });
      if (userData) {
        console.log("exissts", userData);
        return userData.uid;
      } else {
        console.log("Does not exist");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async createNewUserDocument(user) {
    const userCollection = collection(db, "users");

    try {
      const newUserDocRef = await addDoc(userCollection, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        language: "de",
        role: "member",
      });
    } catch (error) {
      console.error("Error creating user document:", error);
    }
  }
}
customElements.define("login-page", login);
