import {
  collection,
  db,
  deleteDoc,
  doc,
  updateDoc,
} from "../../firebase/firebaseConfig.js";
import { throwSnackbar } from "../services/snackBar.js";

export class editService {
  constructor(mode, docID, title, redirect, color) {
    if (mode == "delete") {
      this.deleteQrCode(docID);
    } else if (mode == "edit") {
      this.editQrCode(docID, title, redirect, color);
    }
  }

  async deleteQrCode(docID) {
    const collectionRef = collection(db, "qr-values");
    const documentId = docID;
    const docRef = doc(collectionRef, documentId);

    try {
      await deleteDoc(docRef);
      window.dispatchEvent(new Event("projectSelected"));
      throwSnackbar("Dokument erfolgreich gelöscht", "black", "green");
    } catch (error) {
      throwSnackbar("Fehler beim Löschen des Dokuments", "black", "red");
    }
  }

  async editQrCode(docID, titel, URL, color) {
    const collectionRef = collection(db, "qr-values");
    const documentId = docID;
    const newTitle = titel;
    const newRedirectUrl = URL;

    const docRef = doc(collectionRef, documentId);

    try {
      await updateDoc(docRef, {
        title: newTitle,
        redirectUrl: newRedirectUrl,
        color: color,
      });
      window.dispatchEvent(new Event("projectSelected"));
      throwSnackbar("Dokument erfolgreich aktualisiert", "black", "green");
    } catch (error) {
      throwSnackbar("Fehler beim Aktualisieren des Dokuments", "black", "red");
    }
  }
}
