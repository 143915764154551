
    const css = `#weekDayChart {
  width: 100%;
  margin: 0 auto;
  height: 200px;
  background-color: aliceblue;
  display: flex;
  justify-items: stretch;
  align-items: flex-end;
  justify-content: space-around;
}

.rootWrapper {
  margin: 0 auto;
  width: 80%;
}
.singleChart {
  width: 20px;
  max-height: 200px;
  background-color: #4bc0c0;
  width: calc(100% / 7);
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dayname {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#decrement,
#increment {
  border: none;
  background-color: transparent;
}
.incrementWrapper {
  display: flex;
}
#lineChart {
  background-color: #f0f8ff;
  width: 100%;
  margin: 0 auto;
  height: 200px;
}
#weekNumbers {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wrapper,
.wrapper {
  width: 100%;
}
.data {
  display: flex;
}
.labels {
  width: 30%;
}
.colorIOS {
  background-color: #ff5733;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.colorAndroid {
  background-color: #33ff57;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.colorDesktop {
  background-color: #5733ff;

  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.ios,
.Android,
.Desktop {
  display: flex;
  align-items: center;
  width: 100%;
}`;
    export default css;
  