
    const css = `.qrCodes {
  width: 100%;
  max-width: 900px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  margin: 1rem 0 1rem 2rem;
  z-index: 2;
  border-radius: 5px;
  height: 170px;
}
.qrCodeContainer {
  display: flex;
  width: 20%;
  padding: 2px;
}
.qrCodeContainer div {
  height: 100%;
  width: 100%;
}

.contentContainer {
  margin: 1rem;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.colorPicker {
  height: 50px;
}

img {
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: auto;
  justify-self: end;
  z-index: 1;
  cursor: pointer;
  padding: 7px;
}

.qrCodeContainer svg {
  position: relative;
  left: 50%;
  top: 39%;
  z-index: 10;
  transform: scale(5.5);
  opacity: 0;
  cursor: pointer;
}
#title:disabled {
  width: 60%;
  height: 26px;
  border: none;
  background-color: transparent;
  color: black;
  font-size: 20px;
}
p {
  width: 60%;
  border-radius: 5px;
  height: 26px;
  /* padding: 5px 0 0 5px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
p svg {
  padding-right: 10px;
}

#redirect:disabled {
  background-image: url("open_in_new_FILL0_wght400_GRAD0_opsz24.343a43a4.png");
  background-repeat: no-repeat;
  width: calc(60% - 30px);
  background-color: #ffffff;
  border-radius: 5px;
  height: 26px;
  padding: 0 0 0 30px;
  border: none;
  margin: 1rem 0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

#redirect input {
  flex: 1;
  padding-left: 30px;
}
#redirect {
  width: 60%;
  background-color: #e6e6e6;
  border-radius: 5px;
  height: 26px;
  padding: 5px 0 0 5px;
  margin: 1rem 0;
}
#title {
  width: 60%;
  height: 26px;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 20px;
}
h2 {
  margin: 0;
}

.qrCodeSection {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 3;
}

.buttons {
  flex-direction: column;
  height: 170px;
  position: relative;
  left: -170px;
  z-index: 1;
  /* display: none; */
}
.analiticButton,
.editButton,
.deleteButton,
.downloadButton {
  border: none;
  height: calc(100% / 3);
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}
svg {
  /* margin-left: 20px; */
}

.analiticButton {
  border-radius: 0 5px 0 0;
}
.deleteButton {
  border-radius: 0 0 5px 0;
}

.deleteButton:hover {
  animation: 1s slide-right-full forwards;
}
.editButton:hover {
  animation: 1s slide-right-full forwards;
}
.analiticButton:hover {
  animation: 1s slide-right-full forwards;
}
.analiticButton {
  background-color: #00bbf9;
}
.editButton {
  background-color: #fee440;
}
.deleteButton {
  background-color: #dd0d15;
}
.downloadButton {
  background-color: #00bbf9;
}
.buttons {
}

.qrCodes:hover + .buttons {
  animation: 1s slide-right forwards;
}
.qrCodes:hover .qrCodeContainer svg {
  opacity: 1;
}

.qrCodes:hover .qrCodeContainer img {
  opacity: 20%;
}

.buttons:hover {
  animation: slide-right 1s forwards;
}
@keyframes slide-right {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 60px;
  }
}

@keyframes slide-right-full {
  from {
    margin-left: 60px;
  }
  to {
    margin-left: 110px;
  }
}
.downloadButton {
  display: none;
}

@media only screen and (max-width: 900px) {
  .contentContainer {
    width: calc(100% - 2rem);
  }
  .qrCodeSection {
    flex-direction: column;
    height: 370px;
  }
  .qrCodes {
    flex-direction: column;
    height: auto;
    margin: 0;
    width: 90%;
  }
  .qrCodeContainer {
    width: 40%;
  }

  img {
    margin: 10px;
  }
  .buttons {
    position: relative;
    top: -168px;
    left: 90px;
    z-index: 10;
    height: 120px;
  }
  .downloadButton {
    display: flex;
  }
  .qrCodes:hover + .buttons {
    animation: none !important;
  }

  .buttons:hover {
    animation: none !important;
  }

  .deleteButton:hover {
    animation: none;
  }
  .editButton:hover {
    animation: none;
  }
  .analiticButton:hover {
    animation: none;
  }
  .qrCodes:hover .qrCodeContainer svg {
    opacity: 0;
  }
  .qrCodes:hover .qrCodeContainer img {
    opacity: 1;
  }
  .analiticButton,
  .editButton,
  .deleteButton,
  .downloadButton {
    border-radius: 5px;
    background-color: #d9d9d9;
    margin: 5px;
    height: calc(100% / 4);
    font-size: 14px;
  }
}`;
    export default css;
  