import {
  arrayUnion,
  collection,
  db,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "../../firebase/firebaseConfig.js";
import { throwSnackbar } from "../services/snackBar.js";
import login from "./login.js";
import css from "./projectsSettingsCSS.js";
export default class projectSettings extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("projectSetting-page-template");
    const styles = document.createElement("style");
    const content = template.content.cloneNode(true);
    this.root.appendChild(content);
    this.root.appendChild(styles);
    async function loadCSS() {
      styles.textContent = css;
    }
    loadCSS();
    this.projectID = JSON.parse(localStorage.getItem("selectedProject"));
    const userLocal = JSON.parse(localStorage.getItem("user"));
    this.userIdLocal = userLocal.uid;

    this.render();
  }
  async render() {
    const submitButton = this.root.getElementById("invite");
    submitButton.onclick = (event) => {
      event.preventDefault();
      this.createNewUser();
    };
    const users = await this.getAlluser();
    const adminCount = this.countAdmins(users);
    const userMe = users.find((user) => user.uid === this.userIdLocal);
    const localRole = userMe.role;
    if (localRole !== "admin") {
      const addElement = this.root.getElementById("addMembers");
      addElement.remove();
    }
    const table = this.root.getElementById("adminUserTable");
    users.forEach(async (user) => {
      const userData = await this.getUserData(user.uid);
      const tableElement = document.createElement("tr");
      tableElement.innerHTML = `
      <td id="imgTableData">
        <img src="${userData.photoURL}">
      </td>
      <td>${userData.displayName}</td>
      <td>
      <select id="${userData.uid}" disabled>
      <option>${user.role}</option>
      ${
        user.role == "admin" && adminCount <= 1
          ? ``
          : user.role == "admin"
          ? `<option>member</option> <option>view</option>`
          : user.role == "view"
          ? `<option>admin</option> <option>member</option>`
          : `<option>admin</option> <option>view</option>`
      }
    </select>
    </td>
      <td>${userData.email}</td>
      ${
        localRole === "admin"
          ? `
            <td>
              <div class="dropdown">
                <button class="dropbtn">Aktionen</button>
                <div class="dropdown-content">
                ${
                  userData.uid == this.userIdLocal && adminCount <= 1
                    ? ``
                    : `<button id="removeUser" data-userid="${userData.uid}">
                    User entfernen
                  </button>`
                }
                  <button id="editRoleButton" data-userid="${
                    userData.uid
                  }">Rolle Bearbeiten</button>
                </div>
              </div>
            </td>
          `
          : ""
      }
    `;
      table.appendChild(tableElement);
    });

    if (localRole !== "admin") {
      const headerActions = this.root.getElementById("headerActions");
      headerActions.remove();
    }
    this.root.appendChild(table);
    table.onclick = (event) => {
      const removeUserButton = event.target.closest("#removeUser");
      const editRoleButton = event.target.closest("#editRoleButton");
      if (removeUserButton) {
        const userId = removeUserButton.dataset.userid;
        this.removeuserFromProject(userId);
      }
      if (editRoleButton) {
        const userId = editRoleButton.dataset.userid;
        this.editUserRole(userId);
      }
    };
    const deleteProjectButton = this.root.getElementById("deleteProjectButton");
    deleteProjectButton.onclick = (event) => {
      const dialog = document.getElementById("deleteProject");
      dialog.setAttribute("open", true);
    };

    const confirmButton = document.getElementById("confirmDeleteProject");
    confirmButton.onclick = (event) => {
      console.log("delete project");
      const dialog = document.getElementById("deleteProject");
      dialog.removeAttribute("open");
      this.deleteProject();
    };

    const declineButton = document.getElementById("declineDeleteProject");
    declineButton.onclick = (event) => {
      console.log("NOT DELETE project");
      const dialog = document.getElementById("deleteProject");
      dialog.removeAttribute("open");
    };
  }
  async getAlluser() {
    const projectDocRef = doc(db, "projects", this.projectID);
    try {
      const projectDocSnapshot = await getDoc(projectDocRef);
      if (projectDocSnapshot.exists()) {
        const userField = projectDocSnapshot.get("users");
        return userField;
      } else {
      }
    } catch (error) {}
  }
  countAdmins(users) {
    let counter = 0;
    for (let i = 0; i < users.length; i++) {
      if (users[i].role == "admin") {
        counter++;
      }
    }
    return counter;
  }

  async getUserData(userId) {
    const usersCollectionRef = collection(db, "users");
    try {
      const querySnapshot = await getDocs(
        query(usersCollectionRef, where("uid", "==", userId))
      );

      if (querySnapshot.size > 0) {
        const userData = querySnapshot.docs[0].data();
        return userData;
      } else {
      }
    } catch (error) {}
  }

  async createNewUser() {
    const loginHandler = new login();
    const inputValue = this.root.getElementById("inviteEmail");
    const email = inputValue.value;
    let userExists = await loginHandler.checkIfUserExistByMail(email);
    if (userExists) {
      this.addUserToProject(userExists);
    } else {
      let userId = await loginHandler.createUserAccount(email);
      this.addUserToProject(userId);
    }
  }
  async addUserToProject(userId) {
    const projectDocRef = doc(db, "projects", this.projectID);
    try {
      const projectDocSnapshot = await getDoc(projectDocRef);
      if (projectDocSnapshot.exists()) {
        await updateDoc(projectDocRef, {
          users: arrayUnion({ uid: userId, role: "member" }),
        });
      }
      throwSnackbar("User hinzugefügt", "black", "green");
    } catch (error) {
      throwSnackbar("Fehlgeschlagen", "black", "red");
    }
  }
  async removeuserFromProject(userId) {
    const projectDocRef = doc(db, "projects", this.projectID);
    try {
      const projectDocSnapshot = await getDoc(projectDocRef);
      if (projectDocSnapshot.exists()) {
        const projectData = projectDocSnapshot.data();
        if (projectData.users) {
          const userIndex = projectData.users.findIndex(
            (user) => user.uid === userId
          );
          if (userIndex !== -1) {
            const updatedUsers = [...projectData.users];
            updatedUsers.splice(userIndex, 1);
            await updateDoc(projectDocRef, {
              users: updatedUsers,
            });
            throwSnackbar("Benutzer entfernt", "black", "green");
          } else {
            throwSnackbar("Benutzer nicht gefunden", "black", "red");
          }
        } else {
          throwSnackbar("Benutzer nicht gefunden", "black", "red");
        }
      } else {
        throwSnackbar("Benutzer nicht gefunden", "black", "red");
      }
    } catch (error) {
      throwSnackbar(`Fehler${error}`, "black", "red");
    }
  }
  editUserRole(userId) {
    const selectRole = this.root.getElementById(userId);
    selectRole.removeAttribute("disabled");
    selectRole.addEventListener("change", (event) => {
      const newRole = event.target.value;
      this.updateUserRoleInProject(userId, newRole);
      selectRole.setAttribute("disabled", true);
    });
  }

  async updateUserRoleInProject(userId, newRole) {
    const projectDocRef = doc(db, "projects", this.projectID);

    try {
      const projectDocSnapshot = await getDoc(projectDocRef);

      if (projectDocSnapshot.exists()) {
        const projectData = projectDocSnapshot.data();
        const updatedUsers = projectData.users.map((user) => {
          if (user.uid === userId) {
            // Aktualisiere die Rolle des spezifischen Benutzers
            return { uid: user.uid, role: newRole };
          } else {
            return user;
          }
        });

        await updateDoc(projectDocRef, {
          users: updatedUsers,
        });

        throwSnackbar("Erfolgreich", "black", "green");
      } else {
        throwSnackbar("Benutzer nicht gefunden", "black", "red");
      }
    } catch (error) {
      throwSnackbar(`Fehler: ${error}`, "black", "red");
    }
  }
  async deleteProject() {
    const projectId = this.projectID;

    try {
      const qrValuesCollection = collection(db, "qr-values");
      const qrValuesQuery = query(
        qrValuesCollection,
        where("project", "==", projectId)
      );
      const snapshot = await getDocs(qrValuesQuery);
      snapshot.forEach(async (doc) => {
        console.log(doc.id, "=>", doc.data());
        this.deleteAnalyticsDocument(doc.data().analytic);
        await deleteDoc(doc.ref);
      });
      this.deleteProjectDocument(projectId);
    } catch (error) {
      throwSnackbar("Projekt konnte nicht gelöscht werden", "black", "red");
    }
  }

  async deleteAnalyticsDocument(docId) {
    try {
      const docRef = doc(db, "analytics", docId);
      await deleteDoc(docRef);
    } catch (error) {
      throwSnackbar(
        "Projekt konnte nicht vollständig gelöscht werden",
        "black",
        "red"
      );
    }
  }
  async deleteProjectDocument(docId) {
    try {
      const docRef = doc(db, "projects", docId);
      await deleteDoc(docRef);
      throwSnackbar("Projekt erfolgreich gelöscht", "black", "green");
    } catch (error) {
      throwSnackbar("Projekt konnte nicht gelöscht werden", "black", "red");
    }
  }
}

customElements.define("projectsetting-page", projectSettings);
