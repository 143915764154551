import {
  collection,
  db,
  getDocs,
  query,
  where,
} from "../../firebase/firebaseConfig.js";
import { editService } from "../services/edit.js";
import { QRCodeSVGGenerator } from "../services/qrcode min.js";
import css from "./qrCodeListCSS.js";

let projectIdSaved = "";
export default class QRCodeList extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("qr-code-list-page-template");
    const content = template.content.cloneNode(true);
    const styles = document.createElement("style");
    this.root.appendChild(content);
    this.root.appendChild(styles);
    async function loadCSS() {
      styles.textContent = css;
      // const request = await fetch(
      //   "__parcel_source_root/src/pages/qrCodeList.css"
      // );
      // styles.textContent = await request.text();
    }
    loadCSS();
    window.addEventListener("projectSelected", (event) => {
      if (event.detail) {
        projectIdSaved = event.detail;
        this.fetchAndDisplayQRCodes(event.detail);
      } else {
        this.fetchAndDisplayQRCodes(projectIdSaved);
      }
    });
    this.userRole = JSON.parse(localStorage.getItem("selectedProjectUserRole"));
  }
  connectedCallback() {}

  async fetchAndDisplayQRCodes(projectId) {
    this.clearList();
    try {
      const qrValuesCollection = collection(db, "qr-values");
      const qrValuesQuery = query(
        qrValuesCollection,
        where("project", "==", projectId)
      );
      const snapshot = await getDocs(qrValuesQuery);
      let index = 1;
      const qrCodeWrapper = document.createElement("div");
      qrCodeWrapper.setAttribute("id", "qrCodeWrapper");
      snapshot.forEach((doc) => {
        const sectionElement = document.createElement("div");
        sectionElement.setAttribute("class", "qrCodeSection");

        const docContainer = document.createElement("div");
        docContainer.setAttribute("class", "qrCodes");

        const titleElement = document.createElement("input");
        titleElement.setAttribute("id", "title");
        titleElement.setAttribute("disabled", true);
        titleElement.value = `${doc.data().title}`;

        const redirectUrlElement = document.createElement("input");
        redirectUrlElement.setAttribute("id", "redirect");
        redirectUrlElement.setAttribute("disabled", true);
        redirectUrlElement.value = `${doc.data().redirectUrl}`;

        const linkUrlElement = document.createElement("p");
        linkUrlElement.textContent = `https://qr.ohooo.ch/${doc.id}`;
        linkUrlElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35
          85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83
          0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"/></svg>
          https://qr.ohooo.ch/${doc.id}`;
        const colorPicker = document.createElement("input");
        colorPicker.setAttribute("type", "color");
        colorPicker.style.display = "none";
        colorPicker.setAttribute("id", `colorPicker${index}`);

        const textcontainer = document.createElement("div");
        textcontainer.setAttribute("class", "contentContainer");
        textcontainer.setAttribute("id", index);
        textcontainer.appendChild(titleElement);
        textcontainer.appendChild(redirectUrlElement);
        textcontainer.appendChild(linkUrlElement);
        textcontainer.appendChild(colorPicker);
        docContainer.appendChild(textcontainer);

        const buttonContainer = document.createElement("div");
        buttonContainer.setAttribute("class", "buttons");

        const svgAnalytics = document.createElement("div");
        svgAnalytics.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"/>
      </svg>
      `;

        const svgEdit = document.createElement("div");
        svgEdit.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
      </svg>
      `;

        const svgDelete = document.createElement("div");
        svgDelete.innerHTML = `
        <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z'/>
      </svg>
        `;

        const svgDownload = document.createElement("div");
        svgDownload.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
        `;

        const buttonAnalitics = createButton(
          "Statistik",
          "analiticButton",
          svgAnalytics
        );
        const buttonDownload = createButton(
          "Download",
          "downloadButton",
          svgDownload
        );

        const buttonEdit = createButton("Bearbeiten", "editButton", svgEdit);
        buttonEdit.setAttribute("id", `E${index}`);
        const buttonDelete = createButton("Löschen", "deleteButton", svgDelete);
        buttonDelete.setAttribute("id", `D${index}`);
        buttonContainer.appendChild(buttonAnalitics);
        if (this.userRole != "view") {
          buttonContainer.appendChild(buttonEdit);
          buttonContainer.appendChild(buttonDelete);
          buttonContainer.appendChild(buttonDownload);
        }

        buttonEdit.onclick = (event) => {
          this.root.getElementById("colorPicker");
          colorPicker.style.display = "block";
          const ElementId = event.currentTarget.id;
          this.editQrCode(ElementId, doc.id);
        };
        //for mobile view do not delete
        buttonDownload.onclick = (event) => {
          const imgSrc = qrCodeElement.querySelector("img").src;
          this.downloadQrCOde(doc.data(), imgSrc);
          let redirectUrl = "https://qr.ohooo.ch/" + doc.id;
          var svgNode = QRCodeSVGGenerator({
            msg: redirectUrl,
            dim: 300,
            pad: 6,
            mtx: 7,
            ecl: "H",
            ecb: 0,
            pal: ["#000000", ""],
            vrb: 1,
          });
          this.downloadQrCodeAsSVG(svgNode.outerHTML, doc.data());
        };

        buttonAnalitics.onclick = (event) => {
          const data = doc.data();
          const analiticID = data.analytic;
          localStorage.setItem("analyticID", JSON.stringify(analiticID));
          const routes = {
            route: `/analytics`,
            component: "analytics-page",
          };
          app.router.go(routes);
        };

        buttonDelete.onclick = (event) => {
          const dialogElement = document.getElementById("modal");
          dialogElement.setAttribute("open", true);

          const buttonDeleteDialog = document.getElementById("confirm");
          const buttonNotDeleteDialog = document.getElementById("decline");

          buttonDeleteDialog.onclick = (event) => {
            const dialogElement = document.getElementById("modal");
            dialogElement.removeAttribute("open");
            new editService("delete", doc.id);
          };

          buttonNotDeleteDialog.addEventListener("click", () => {
            const dialogElement = document.getElementById("modal");
            dialogElement.removeAttribute("open");
          });
        };

        document.body.appendChild(buttonContainer);

        function createButton(text, className, svgElement) {
          const button = document.createElement("button");
          button.textContent = text;
          button.setAttribute("class", className);
          button.appendChild(svgElement.cloneNode(true));
          return button;
        }

        const qrCodeElement = document.createElement("div");
        qrCodeElement.setAttribute("id", "qrCodeWrapper");
        var qrcode = new QRCode(qrCodeElement, {
          colorDark: doc.data().color,
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });

        function makeCode() {
          var elText = `https://qr.ohooo.ch/${doc.id}`;
          qrcode.makeCode(elText);
        }
        makeCode();
        const qrCodeContainer = document.createElement("div");
        qrCodeContainer.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>
        `;
        qrCodeContainer.setAttribute("class", "qrCodeContainer");
        //for ful view do not delete
        qrCodeContainer.addEventListener("click", () => {
          const imgSrc = qrCodeElement.querySelector("img").src;
          this.downloadQrCOde(doc.data(), imgSrc);
          let redirectUrl = "https://qr.ohooo.ch/" + doc.id;
          var svgNode = QRCodeSVGGenerator({
            msg: redirectUrl,
            dim: 300,
            pad: 6,
            mtx: 7,
            ecl: "H",
            ecb: 0,
            pal: ["#000000", ""],
            vrb: 1,
          });
          this.downloadQrCodeAsSVG(svgNode.outerHTML, doc.data());
        });
        qrCodeContainer.appendChild(qrCodeElement);
        docContainer.appendChild(qrCodeContainer);
        sectionElement.appendChild(docContainer);
        sectionElement.appendChild(buttonContainer);
        qrCodeWrapper.appendChild(sectionElement);
        index++;
      });
      this.root.appendChild(qrCodeWrapper);
    } catch (error) {
      console.error("Error fetching and displaying QR codes:", error);
    }
  }
  clearList() {
    try {
      const qrCodeList = this.root.getElementById("qrCodeWrapper");
      qrCodeList.remove();
    } catch (error) {
      console.error("Error fetching and displaying QR codes:", error);
    }
  }
  async downloadQrCOde(data, imageSrc) {
    const image = await fetch(imageSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `${data.title}-QR`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadQrCodeAsSVG(svg, data) {
    const htmlStr = svg;
    const blob = new Blob([htmlStr], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    const fileName = data.title + " QR" || "Qr-Code";
    a.setAttribute("download", `${fileName}.svg`);
    a.setAttribute("href", url);
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  }

  editQrCode(id, docID) {
    const elementID = id.slice(1);
    const deleteID = `D${elementID}`;
    const inputElement = this.root.getElementById(elementID);
    const titleElement = inputElement.querySelector("#title");
    const redirectElement = inputElement.querySelector("#redirect");
    const editButton = this.root.getElementById(id);
    const deleteButton = this.root.getElementById(deleteID);

    const titleValue = titleElement.value;
    const redirectValue = redirectElement.value;

    editButton.onclick = (event) => {
      this.editQrCodeSave(id, docID);
      this.root.getElementById("colorPicker");
      colorPicker.style.display = "none";
    };

    deleteButton.onclick = (event) => {
      this.cancelEdit(id, docID, titleValue, redirectValue);
    };

    deleteButton.innerHTML =
      "Abbrechen <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z'/></svg>";

    editButton.innerHTML =
      "Speichern <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z'/></svg>";

    titleElement.removeAttribute("disabled");
    redirectElement.removeAttribute("disabled");
  }

  editQrCodeSave(id, docID) {
    const elementID = id.slice(1);
    const deleteID = `D${elementID}`;
    const inputElement = this.root.getElementById(elementID);
    const titleElement = inputElement.querySelector("#title");
    const redirectElement = inputElement.querySelector("#redirect");
    const editButton = this.root.getElementById(id);
    const deleteButton = this.root.getElementById(deleteID);
    const colorPicker = this.root.getElementById(`colorPicker${elementID}`);
    const colorPickerValue = colorPicker.value;
    editButton.onclick = (event) => {
      const ElementId = event.currentTarget.id;
      this.editQrCode(ElementId, docID);
    };

    deleteButton.onclick = (event) => {
      const dialogElement = document.getElementById("modal");
      dialogElement.setAttribute("open", true);
    };

    editButton.innerHTML =
      "Bearbeiten <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z'></path></svg>";

    deleteButton.innerHTML =
      "Löschen <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z'></path></svg>";
    titleElement.setAttribute("disabled", true);
    redirectElement.setAttribute("disabled", true);

    let titelElementContent = titleElement.value;
    let redirectElementContent = redirectElement.value;

    new editService(
      "edit",
      docID,
      titelElementContent,
      redirectElementContent,
      colorPickerValue
    );
  }

  cancelEdit(id, docID, titleValue, redirectValue) {
    const elementID = id.slice(1);
    const deleteID = `D${elementID}`;
    const inputElement = this.root.getElementById(elementID);
    const titleElement = inputElement.querySelector("#title");
    const redirectElement = inputElement.querySelector("#redirect");
    const editButton = this.root.getElementById(id);
    const deleteButton = this.root.getElementById(deleteID);

    deleteButton.onclick = (event) => {
      const dialogElement = document.getElementById("modal");
      dialogElement.setAttribute("open", true);
    };

    editButton.onclick = (event) => {
      const ElementId = event.currentTarget.id;
      this.editQrCode(ElementId, docID);
    };
    const colorPicker = this.root.getElementById(`colorPicker${elementID}`);
    colorPicker.style.display = "none";

    titleElement.value = titleValue;
    redirectElement.value = redirectValue;

    editButton.innerHTML =
      "Bearbeiten <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z'></path></svg>";

    deleteButton.innerHTML =
      "Löschen <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z'></path></svg>";
    titleElement.setAttribute("disabled", true);
    redirectElement.setAttribute("disabled", true);
  }
}

// Define the custom element
customElements.define("qr-code-list-page", QRCodeList);
