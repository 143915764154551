import analytics from "./pages/analytics.js";
import login from "./pages/login.js";
import pageNotFound from "./pages/pageNotFound.js";
import profile from "./pages/profile.js";
import projectSettings from "./pages/projectsSettings.js";
import QRCodeList from "./pages/qrCodeList.js";
import Router from "./router.js";
import { checkislogedIn } from "./services/auth.js";
import { redirect } from "./services/redirect.js";

window.app = {};
app.router = Router;
QRCodeList;
redirect;
pageNotFound;
analytics;
login;
profile;
projectSettings;

export const routes = {
  "/": "login-page",
  "/projectsettings": "projectsetting-page",
  "/qr-codes": "qr-code-list-page",
  "/profile": "profile-page",
  "/404": "failed-page",
  "/analytics": "analytics-page",
};

addEventListener("DOMContentLoaded", async () => {
  app.router.init({ routes });
  const pathName = window.location.pathname;
  if (
    pathName.length > 1 &&
    pathName !== "/profile" &&
    pathName !== "/qr-codes" &&
    pathName !== "/projectsettings" &&
    pathName !== "/analytics"
  ) {
    new redirect(pathName);
    return;
  }
  const isLoggedIn = await checkislogedIn();
  if (pathName === "/qr-codes" && !isLoggedIn) {
    const routes = {
      route: "/",
      component: "login-page",
    };
    app.router.go(routes);
  }
});
