export function throwSnackbar(text, color, backgroundColor) {
  const snackBar = document.getElementById("snackbar");
  snackBar.innerText = text;
  snackBar.setAttribute("class", "show");
  snackBar.style.color = color;
  snackBar.style.backgroundColor = backgroundColor;
  setTimeout(() => {
    snackBar.removeAttribute("class");
  }, 2000);
}
