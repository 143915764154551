import {
  arrayUnion,
  db,
  doc,
  getDoc,
  updateDoc,
} from "../../firebase/firebaseConfig.js";

export class redirect {
  constructor(url) {
    document.body.innerHTML = "";
    //set background color of body to white
    document.body.style.maxWidth = "100vw";
    this.url = url;
    this.fetchData();
  }

  async fetchData() {
    const docRef = doc(db, "qr-values", this.url);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const docId = data.analytic;
      await this.saveStatistic(docId);
      this.redirectInTab(data);
    } else {
      const routes = {
        route: "404",
        component: "failed-page",
      };
      app.router.go(routes);
    }
  }
  catch(error) {
    console.error("Failed to get document", error);
  }

  redirectInTab(data) {
    window.open(data.redirectUrl, "_self");
  }

  async saveStatistic(documentID) {
    var benutzerInfo = this.getDeviceInfo();
    try {
      const docRef = doc(db, "analytics", documentID);
      const updatedData = {
        clicks: arrayUnion({ date: new Date(), platform: benutzerInfo }),
      };
      await updateDoc(docRef, updatedData);
    } catch (e) {
      console.error("Fehler beim Protokollieren des Clicks:", e);
    }
  }
  getDeviceInfo() {
    var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    var isAndroid = /Android/i.test(navigator.userAgent);

    if (isIOS) {
      return "iOS";
    } else if (isAndroid) {
      return "Android";
    } else {
      return "Desktop";
    }
  }

  // Beispielaufruf der Funktion
}
