import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateProfile,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

//@TODO LIVE DB
const firebaseConfig = {
  apiKey: "AIzaSyAkDwRDPluucx0S3pxSdWBHxhz84pNKER8",
  authDomain: "qr-ohooo.firebaseapp.com",
  projectId: "qr-ohooo",
  storageBucket: "qr-ohooo.appspot.com",
  messagingSenderId: "93640157297",
  appId: "1:93640157297:web:84cfbaea64e97db5dd13d0",
  measurementId: "G-Z4BD4T300F",
};
//@TODO DEV DB
// const firebaseConfig = {
//   apiKey: "AIzaSyBTeoF_Di0lZwvqMUvJTHzLVXCypGFh1Zg",
//   authDomain: "qr-ohooo-dev.firebaseapp.com",
//   projectId: "qr-ohooo-dev",
//   storageBucket: "qr-ohooo-dev.appspot.com",
//   messagingSenderId: "811798331668",
//   appId: "1:811798331668:web:c64b078e723cf66e360201",
// };

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage();

export {
  addDoc,
  arrayRemove,
  arrayUnion,
  auth,
  collection,
  createUserWithEmailAndPassword,
  db,
  deleteDoc,
  deleteUser,
  doc,
  getDoc,
  getDocs,
  getDownloadURL,
  getRedirectResult,
  onAuthStateChanged,
  provider,
  query,
  ref,
  sendPasswordResetEmail,
  setDoc,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  storage,
  updateDoc,
  updateProfile,
  uploadBytes,
  where,
};
