
    const css = `.loginBlock {
  position: relative;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 400px;
  transform: translate(-50%, 100%);
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
* {
  font-family: regular;
}
h1 {
  text-align: center;
  font-family: header;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  margin: 5px 0;
  width: 90%;
  height: 20px;
  border-radius: 8px;
  border: none;
  background-color: #a2ded06f;
}
label {
  align-self: flex-start;
  margin: 0 5%;
}
button {
  background-color: #a2ded0;
  height: 40px;
  width: 90%;
  margin: 10px 0;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
}

/* Helle Blaugrün: #A2DED0
Pastellrosa: #F5B7B1
Zartes Gelb: #FFF1B5 */`;
    export default css;
  