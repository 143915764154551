
    const css = `.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}
li {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}
tr {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
button {
  width: 100px;
  width: auto;
  min-width: 100px;
  max-width: 200px;
  min-height: 30px;
  height: auto;
  border-radius: 5px;
  border: none;
  background-color: #a2ded0;
  cursor: pointer;
}
svg {
  cursor: pointer;
}
ul {
  padding: 0;
  width: 100%;
  border: solid 1px black;
  display: flex;
  flex-direction: column;
}
p {
  margin: 0;
}
.buttons {
  width: 60%;
}

#imageContainer {
  position: relative;
  display: inline-block;
}

#userImage {
  max-width: 100%; /* Stelle sicher, dass das Bild nicht breiter als sein Container ist */
}
.userNameWrapper {
  display: flex;
}
#userName {
  border: none;
  color: black;
  text-align: center;
  font-size: 20px;
}

#editIcon {
  position: absolute;
  top: 8px;
  right: 0;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
}

#resetPasswordEmail {
  /* width: 200px;
  height: auto; */
}
hr {
  width: 90%;
  align-self: center;
  margin: 0;
}`;
    export default css;
  