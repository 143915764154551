import { db, doc, getDoc } from "../../firebase/firebaseConfig.js";
import css from "./analyticsCSS.js";

let analyticsID = "";
export default class analytics extends HTMLElement {
  constructor() {
    super();
    this.firebaseData = "";
    const currentDate = new Date();
    this.monthIndex = currentDate.getMonth() + 1;
    this.yearIndex = currentDate.getFullYear();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("analytics-page-template");
    const content = template.content.cloneNode(true);
    const styles = document.createElement("style");
    this.root.appendChild(styles);
    this.root.appendChild(content);
    async function loadCSS() {
      styles.textContent = css;
    }
    loadCSS();
    analyticsID = JSON.parse(localStorage.getItem("analyticID"));
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.loadData();
    this.addListeners();
  }

  addListeners() {
    const incrementButton = this.root.getElementById("increment");
    const decrementButton = this.root.getElementById("decrement");
    incrementButton.onclick = (event) => {
      if (this.monthIndex < 12) {
        this.monthIndex += 1;
      } else {
        this.monthIndex = 1;
        this.yearIndex += 1;
      }
      this.sortingDataByMonth();
    };
    decrementButton.onclick = (event) => {
      if (this.monthIndex > 1) {
        this.monthIndex -= 1;
      } else {
        this.monthIndex = 12;
        this.yearIndex -= 1;
      }
      this.sortingDataByMonth();
    };
  }

  async loadData() {
    const docRef = doc(db, "analytics", analyticsID);
    const docSnap = await getDoc(docRef);
    this.firebaseData = docSnap.data();
    if (this.firebaseData?.clicks?.length > 0) {
      this.mappingDataWeekday();
      this.sortingDataByMonth();
      this.renderPieChart();
    } else {
      const wrapper = this.root.querySelector(".rootWrapper");
      wrapper.innerHTML = `
      <h2>Im Moment sind noch keine daten Vorhanden</h2>
      `;
    }
  }

  mappingDataWeekday() {
    const dataFirebase = this.firebaseData;
    const weekdayArrays = {
      // 0: 33,
      // 1: 22,
      // 2: 65,
      // 3: 44,
      // 4: 3,
      // 5: 65,
      // 6: 12,
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };
    if (Array.isArray(dataFirebase.clicks)) {
      dataFirebase.clicks.forEach((data) => {
        const dayOfWeek = new Date(data.date.seconds * 1000).getDay();
        const adjustedDayOfWeek = (dayOfWeek + 6) % 7;
        weekdayArrays[adjustedDayOfWeek]++;
      });
    } else {
      console.error("dataFirebase.clicks is not an array");
    }
    this.renderWeekdayChart(weekdayArrays);
  }
  renderWeekdayChart(data) {
    const maxNumber = this.findMaxValue(data);
    const wrapperAll = this.root.querySelector(".rootWrapper");
    const wrapper = this.root.querySelector(".wrapperWeekday");
    const chartWeekday = this.root.getElementById("weekDayChart");
    for (let i = 0; i < 7; i++) {
      const chartElement = document.createElement("div");
      chartElement.setAttribute("class", "singleChart");
      const relativeHeight = (data[i] / maxNumber) * 100;
      chartElement.style.height = `${relativeHeight}%`;
      if (data[i] > 0) {
        chartElement.textContent = data[i];
      }
      chartWeekday.appendChild(chartElement);
    }

    const weekDays = document.createElement("div");
    weekDays.innerHTML = `
  <div class="dayname">
    <p>MO</p>
    <p>DI</p>
    <p>MI</p>
    <p>DO</p>
    <p>FR</p>
    <p>SA</p>
    <p>SO</p>
  </div>`;
    wrapper.appendChild(chartWeekday);
    wrapper.appendChild(weekDays);
    wrapperAll.appendChild(wrapper);
    this.root.appendChild(wrapperAll);
    // this.root.appendChild(chartWeekday);
    // this.root.appendChild(weekDays);
  }
  sortingDataByMonth() {
    const data = this.firebaseData;
    data.clicks.sort((a, b) => a.date.seconds - b.date.seconds);
    const sortedData = data.clicks.reduce((acc, click) => {
      const date = new Date(click.date.seconds * 1000); // Konvertiere Sekunden in Millisekunden
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }

      acc[monthYear].push(click);
      return acc;
    }, {});
    for (const month in sortedData) {
      if (sortedData.hasOwnProperty(month)) {
        const dayCounter = new Map();

        sortedData[month].forEach((entry) => {
          const entryDate = new Date(
            entry.date.seconds * 1000
          ).toLocaleDateString();

          if (dayCounter.has(entryDate)) {
            dayCounter.set(entryDate, dayCounter.get(entryDate) + 1);
          } else {
            dayCounter.set(entryDate, 1);
          }
        });
        sortedData[month] = Array.from(dayCounter, ([date, counter]) => ({
          date,
          counter,
        }));
      }
    }
    this.fillMonth(sortedData);
  }
  fillMonth(data) {
    const months = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];
    let selectedMonth = this.monthIndex;
    let selectedYear = this.yearIndex;
    try {
      let monthData = Object.values(data[`${selectedMonth}-${selectedYear}`]);
      const filledMonthData = [];
      let daysOfMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      const existingDays = new Set(monthData.map((entry) => entry.date));

      for (let day = 1; day <= daysOfMonth; day++) {
        const currentDate = new Date(
          `${monthData[0].date.split(".")[1]}.${day}.${
            monthData[0].date.split(".")[2]
          }`
        );
        const formattedDate = currentDate.toLocaleDateString();

        if (!existingDays.has(formattedDate)) {
          filledMonthData.push({
            date: formattedDate,
            counter: 0,
          });
        } else {
          let existingDate = monthData.find(
            (date) => date.date === formattedDate
          );
          filledMonthData.push(existingDate);
        }
      }
      this.renderValuesLineChart(filledMonthData);
    } catch (error) {
      let monthData = [];
      this.renderValuesLineChart(monthData);
    }
    const monthName = this.root.querySelector("#monthName");
    monthName.textContent = `${months[selectedMonth - 1]} ${selectedYear}`;
  }
  renderValuesLineChart(data) {
    var canvas = this.root.getElementById("lineChart");
    var ctx = canvas.getContext("2d");
    var dpr = window.devicePixelRatio || 1;
    var rect = canvas.getBoundingClientRect();
    var scaleFactor = window.devicePixelRatio || 1;

    if (data.length == 0) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = rect.width * scaleFactor;
      canvas.height = rect.height * scaleFactor;
      ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
      ctx.font = "30px Arial";
      var text = "Keine Daten vorhanden";
      var textWidth = ctx.measureText(text).width;
      var x = (canvas.width - textWidth) / 2;
      var y = 50;
      ctx.fillText(text, x, y);
    } else {
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);
      var maxValue = this.findMaxValueForLine(data);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (data.length === 0 || maxValue === 0) {
        console.error("No data or maxValue is zero.");
        return;
      }

      var xOffset = 20;
      var yOffset = 20;
      var canvasWidthAdjusted = canvas.width - 2 * xOffset;
      var canvasHeightAdjusted = canvas.height - 2 * yOffset;

      ctx.beginPath();
      ctx.moveTo(
        xOffset,
        canvas.height -
          (data[0].counter / maxValue) * canvasHeightAdjusted -
          yOffset
      );
      for (var i = 1; i < data.length; i++) {
        var x1 = xOffset + (canvasWidthAdjusted / (data.length - 1)) * (i - 1);
        var y1 =
          canvas.height -
          (data[i - 1].counter / maxValue) * canvasHeightAdjusted -
          yOffset;
        var x2 = xOffset + (canvasWidthAdjusted / (data.length - 1)) * i;
        var y2 =
          canvas.height -
          (data[i].counter / maxValue) * canvasHeightAdjusted -
          yOffset;
        var cx = (x1 + x2) / 2;
        ctx.bezierCurveTo(cx, y1, cx, y2, x2, y2);
      }
      ctx.strokeStyle = "rgb(75, 192, 192)";
      ctx.lineWidth = 2;
      ctx.stroke();

      ctx.fillStyle = "black";

      for (var i = 0; i < data.length; i++) {
        var x = xOffset + (canvasWidthAdjusted / (data.length - 1)) * i;
        var y =
          canvas.height -
          (data[i].counter / maxValue) * canvasHeightAdjusted -
          yOffset;
        if (data[i].counter > 0) {
          ctx.beginPath();
          ctx.arc(x, y, 4, 0, 2 * Math.PI);
          ctx.fill();
          //date
          ctx.font = "15px Arial";
          var text = i + 1 + "." + this.monthIndex;
          var textWidth = ctx.measureText(text).width;
          ctx.fillText(text, x - textWidth / 2, canvas.height - 10);
          //count
          ctx.font = "15px Arial";
          var text = data[i].counter;
          var textWidth = ctx.measureText(text).width;
          ctx.fillText(text, x - textWidth / 2, y - 10); // Platzieren Sie den Text oberhalb des Punktes
        }
      }
    }
  }

  renderPieChart() {
    const dataFirebase = this.firebaseData;
    const clicks = this.countClicks(dataFirebase);
    const length = dataFirebase.clicks.length;
    const data = [
      100 * (clicks["iOS"] / length),
      100 * (clicks["Android"] / length),
      100 * (clicks["Desktop"] / length),
    ];
    for (let i = 0; i < data.length; i++) {
      if (isNaN(data[i])) {
        data[i] = 0;
      }
    }
    const colors = ["#ff5733", "#33ff57", "#5733ff"];
    const canvas = this.root.getElementById("pieChart");
    const context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);
    let startAngle = 0;

    for (let i = 0; i < data.length; i++) {
      const sliceAngle = (data[i] / 100) * 2 * Math.PI;

      context.beginPath();
      context.moveTo(centerX, centerY);
      context.arc(
        centerX,
        centerY,
        radius,
        startAngle,
        startAngle + sliceAngle
      );
      context.fillStyle = colors[i];
      context.fill();
      startAngle += sliceAngle;
    }
    this.renderPrecent(data);
  }
  renderPrecent(data) {
    const ios = this.root.querySelector("#iosText");
    ios.textContent = `IOS ${data[0].toFixed(2)} %`;
    const android = this.root.querySelector("#androidText");
    android.textContent = `Android ${data[1].toFixed(2)} %`;
    const Desktop = this.root.querySelector("#desktopText");
    Desktop.textContent = `Desktop ${data[2].toFixed(2)} %`;
  }

  countClicks(clicks) {
    if (!Array.isArray(clicks.clicks)) {
      console.error('Die Variable "clicks" ist kein Array.');
      return {};
    }
    const clickCounts = {};
    clicks.clicks.forEach((click) => {
      const platform = click.platform;
      clickCounts[platform] = (clickCounts[platform] || 0) + 1;
    });

    return clickCounts;
  }

  findMaxValueForLine(data) {
    let max = -Infinity;
    for (var i = 0; i < data.length; i++) {
      var value = data[i].counter;
      if (value > max) {
        max = value;
      }
    }
    return max;
  }
  findMaxValue(obj) {
    let max = -Infinity;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value > max) {
          max = value;
        }
      }
    }
    return max;
  }
}

customElements.define("analytics-page", analytics);
