import {
  auth,
  collection,
  db,
  deleteDoc,
  deleteUser,
  doc,
  getDoc,
  getDocs,
  onAuthStateChanged,
  query,
  sendPasswordResetEmail,
  signOut,
  updateDoc,
  where,
} from "../../firebase/firebaseConfig.js";
export function checkislogedIn() {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}
export function resetPassword(email) {
  sendPasswordResetEmail(auth, email)
    .then(() => {})
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
}

export async function deleteAccount(projects) {
  const user = auth.currentUser;
  if (user) {
    delteInCollection(user.uid);
    removeFromProjects(user.uid, projects);
    deleteUser(user)
      .then(() => {})
      .catch((error) => {
        throwSnackbar("Account konnte nicht gelöscht werden", "black", "red");
      });
    SignOutUser();
  }
}

async function delteInCollection(uid) {
  const usersCollection = collection(db, "users");
  const userQuery = query(usersCollection, where("uid", "==", uid));
  try {
    const querySnapshot = await getDocs(userQuery);
    if (!querySnapshot.empty) {
      const userDocument = querySnapshot.docs[0];
      const docRef = userDocument.ref;
      await deleteDoc(docRef);
      throwSnackbar("Dokument erfolgreich gelöscht", "black", "green");
    } else {
    }
  } catch (error) {
    throwSnackbar("Dokument konnte nicht gelöscht werden", "black", "red");
  }
}

async function removeFromProjects(userId, projects) {
  projects.forEach(async (project) => {
    const projectDocRef = doc(db, "projects", project.id);
    try {
      const projectDocSnapshot = await getDoc(projectDocRef);
      if (projectDocSnapshot.exists()) {
        const projectData = projectDocSnapshot.data();
        if (projectData.users) {
          const userIndex = projectData.users.findIndex(
            (user) => user.uid === userId
          );
          if (userIndex !== -1) {
            const updatedUsers = [...projectData.users];
            updatedUsers.splice(userIndex, 1);
            await updateDoc(projectDocRef, {
              users: updatedUsers,
            });
            throwSnackbar("User erfolgreich entfernt", "black", "green");
            if (projectData.users.length <= 1) {
              await deleteDoc(projectDocRef);
            }
          } else {
          }
        } else {
        }
      } else {
      }
    } catch (error) {
      throwSnackbar("User konnte nicht entfernt werden", "black", "red");
    }
  });
}

export function SignOutUser() {
  signOut(auth)
    .then(() => {
      localStorage.clear();
      const routes = {
        route: "/",
        component: "login-page",
      };
      app.router.go(routes);
    })
    .catch((error) => {
      throwSnackbar("Fehler beim ausloggen", "black", "red");
    });
}
export function delteUserFromDB() {
  const user = auth.currentUser;
  deleteUser(user)
    .then(() => {})
    .catch((error) => {});
}
window.addEventListener("UserDataChanged", () => {
  laodData();
});
export function laodData() {
  const user = auth.currentUser;
  if (user !== null) {
    user.providerData.forEach((profile) => {
      const userStorage = JSON.parse(localStorage.getItem("user")) || {};
      userStorage.displayName = profile.displayName;
      userStorage.photoURL = profile.photoURL;
      localStorage.setItem("user", JSON.stringify(userStorage));

      const storedDisplayName = JSON.parse(
        localStorage.getItem("user.displayName")
      );
    });
  }
}
