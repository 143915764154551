import {
  auth,
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  getDownloadURL,
  query,
  ref,
  storage,
  updateDoc,
  updateProfile,
  uploadBytes,
  where,
} from "../../firebase/firebaseConfig.js";
import { deleteAccount, resetPassword } from "../services/auth.js";
import { throwSnackbar } from "../services/snackBar.js";
import css from "./profileCSS.js";
export default class profile extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: "open" });
    const template = document.getElementById("profile-page-template");
    const content = template.content.cloneNode(true);
    const styles = document.createElement("style");
    this.root.appendChild(styles);
    this.root.appendChild(content);
    async function loadCSS() {
      styles.textContent = css;
    }
    loadCSS();
    this.userProjects = "";
    this.userData = JSON.parse(localStorage.getItem("user"));
  }

  connectedCallback() {
    this.render();
  }

  async render() {
    const userProjects = await this.getprojects();
    const userName = this.root.getElementById("userName");
    const table = this.root.getElementById("projectTableBody");
    userProjects.forEach((project) => {
      const list = document.createElement("li");
      const tableRow = document.createElement("tr");
      const tableDataTitle = document.createElement("td");
      const tableDataButton = document.createElement("td");
      const leaveProjectButton = document.createElement("button");
      leaveProjectButton.setAttribute("id", "leaveProject");
      tableDataTitle.textContent = project.title;
      leaveProjectButton.textContent = "Verlassen";
      leaveProjectButton.dataset.projectId = project.id;

      tableDataButton.appendChild(leaveProjectButton);
      tableRow.appendChild(tableDataTitle);
      tableRow.appendChild(tableDataButton);
      list.appendChild(tableRow);
      table.appendChild(list);

      leaveProjectButton.addEventListener("click", (event) => {
        const projectId = event.target.dataset.projectId;
        this.getOutProject(projectId, this.userData.uid);
      });
    });
    const profileImage = this.root.getElementById("userImage");
    if (this.userData.photoURL) {
      profileImage.src = this.userData.photoURL;
    }
    if (this.userData.displayName) {
      userName.value = this.userData.displayName;
    } else {
      userName.value = "Noch keinen Namen festgelegt!";
      userName.style.color = "red";
    }

    const userNameButton = this.root.getElementById("editNameIcon");
    userNameButton.onclick = () => {
      userName.removeAttribute("disabled");
      userNameButton.textContent = "Speichern";
      userNameButton.onclick = (event) => {
        event.preventDefault();
        const displayName = userName.value;
        userName.setAttribute("disabled", true);
        userNameButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
          <path
            d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
        </svg>
        `;
        this.updateUserName(displayName);
      };
    };
    const inputElement = this.root.getElementById("newUserImage");
    inputElement.addEventListener("change", () => {
      this.displaySelectedImage();
    });

    const resetPass = this.root.getElementById("resetPasswordEmail");
    resetPass.onclick = () => {
      resetPassword(this.userData.email);
    };
    const deleteAcc = this.root.getElementById("deleteAccount");
    deleteAcc.onclick = () => {
      deleteAccount(userProjects);
    };
  }

  async updateUserName(NewDisplayName) {
    try {
      await updateProfile(auth.currentUser, {
        displayName: NewDisplayName,
      });
      try {
        let userID = this.userData.uid;
        const q = query(collection(db, "users"), where("uid", "==", userID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          const userDocRef = doc.ref;
          await updateDoc(userDocRef, {
            displayName: NewDisplayName,
          });
        });
        throwSnackbar("Erolgreiches Update", "black", "green");
        window.dispatchEvent(new Event("UserDataChanged"));
      } catch (error) {}
    } catch (error) {
      throwSnackbar("Fehler beim update", "black", "red");
    }
  }
  async updateUserImage(imageURL) {
    try {
      await updateProfile(auth.currentUser, {
        photoURL: imageURL,
      });
      let userID = this.userData.uid;
      const q = query(collection(db, "users"), where("uid", "==", userID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        const userDocRef = doc.ref;
        await updateDoc(userDocRef, {
          photoURL: imageURL,
        });
      });
      throwSnackbar("Erolgreiches Update", "black", "green");
      window.dispatchEvent(new Event("UserDataChanged"));
    } catch (error) {
      throwSnackbar("Fehler beim update", "black", "red");
    }
  }
  displaySelectedImage() {
    const inputElement = this.root.getElementById("newUserImage");
    const imageElement = this.root.getElementById("userImage");
    if (inputElement.files && inputElement.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        imageElement.src = e.target.result;
      };
      reader.readAsDataURL(inputElement.files[0]);
      this.uploadImage(inputElement.files[0]);
    } else {
      imageElement.src = "../../user-icon-default.png";
    }
  }
  async uploadImage(file) {
    const storageRef = ref(storage, `profileImages/${file.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      this.updateUserImage(downloadURL);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  }
  async getprojects() {
    const uid = JSON.parse(localStorage.getItem("user") || "{}").uid;
    const projectsCollection = collection(db, "projects");

    try {
      const querySnapshot = await getDocs(projectsCollection);
      let projects = [];

      querySnapshot.forEach((doc) => {
        const project = doc.data();
        project.id = doc.id;
        if (project.users && Array.isArray(project.users)) {
          const foundUser = project.users.find((user) => user.uid === uid);
          if (foundUser) {
            projects.push(project);
          }
        }
      });
      return projects;
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async getOutProject(projectId, userId) {
    console.log("Hello world", projectId);
    const projectDocRef = doc(db, "projects", projectId);
    try {
      const projectDocSnapshot = await getDoc(projectDocRef);
      if (projectDocSnapshot.exists()) {
        const projectData = projectDocSnapshot.data();
        if (projectData.users) {
          const userIndex = projectData.users.findIndex(
            (user) => user.uid === userId
          );
          if (userIndex !== -1) {
            const updatedUsers = [...projectData.users];
            updatedUsers.splice(userIndex, 1);
            await updateDoc(projectDocRef, {
              users: updatedUsers,
            });
            throwSnackbar("Benutzer entfernt", "black", "green");
          } else {
            throwSnackbar("Benutzer nicht gefunden", "black", "red");
          }
        } else {
          throwSnackbar("Benutzer nicht gefunden", "black", "red");
        }
      } else {
        throwSnackbar("Benutzer nicht gefunden", "black", "red");
      }
    } catch (error) {
      throwSnackbar(`Fehler${error}`, "black", "red");
    }
  }
}

customElements.define("profile-page", profile);
