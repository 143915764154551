
    const css = `input,
button {
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
}
img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
}
table {
  margin: 0 auto;
  width: 80%;
  background-color: #a0a0a0;
}
td {
  height: 40px;
  background-color: #ffffff;
  padding: 0 0 0 5px;
}
select {
  border: none;
}
select:disabled {
  color: black;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#imgTableData {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropbtn {
  background-color: transparent;
  color: black;
  width: 100%;
  height: 100%;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
#deleteProjectButton {
  position: absolute;
  width: 160px;
  bottom: 60px;
  left: calc(50% - 80px);
  background-color: red;
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  left: 30px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
#removeUser:hover,
#editRoleButton:hover {
  background-color: aqua;
  cursor: pointer;
}
.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  border: none;
  border-bottom: solid 1px black;
}
.dropdown-content button:last-child {
  border: none;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}`;
    export default css;
  